@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.block-renderer figure {
  width: 100%;
  text-align: center;
  margin: 0;
}

.block-renderer figure img {
  margin: 0 auto;
}

.block-renderer table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.block-renderer table td {
  padding: 0.5rem;
  border: thin solid var(--amplify-colors-neutral-40);
  border-collapse: collapse;
  vertical-align: top;
}

.block-renderer blockquote {
  position: relative;
}

.block-renderer blockquote footer {
  font-style: italic;
  font-size: 0.9em;
  margin-top: -0.5rem;
  margin-left: 1rem;
}

.block-renderer blockquote::before {
  content: open-quote;
  color: var(--amplify-colors-neutral-40);
  position: absolute;
  font-size: 3em;
  left: -0.4em;
  top: -0.4em;
}

.block-renderer blockquote::after {
  content: close-quote;
  position: absolute;
  opacity: 0;
}

.html-editor::before {
  content: "HTML:";
  font-weight: bold;
  display: block;
  text-align: center;
}

.html-editor {
  margin: 0.5rem;
  max-width: 400px;
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.style-editor {
  margin: 0.5rem;
  max-width: 400px;
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.html-editor-instructions {
  font-size: 0.8em;
  margin: 0.5rem;
  background: var(--amplify-colors-neutral-40);
  padding: 0.25rem;
}

.html-editor-instructions pre {
  display: inline;
  white-space: pre-wrap;
}

*:has(> .expand-to-outer) {
  padding: 0 !important;
  position: relative;
}

.amplify-expander__header *:has(> .expand-to-outer) {
  padding: initial !important;
  position: initial;
}

.amplify-expander__header:has(.expand-to-outer) {
  position: relative;
}

.amplify-expander__header:has(.expand-to-outer) .amplify-expander__icon {
  z-index: 2;
}

.amplify-expander__header .expand-to-outer {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 var(--amplify-components-expander-trigger-padding-inline-start);
  display: flex;
  align-items: center;
  z-index: 0;
}

.amplify-expander__header:hover .expand-to-outer::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--amplify-colors-overlay-10);
  z-index: 0;
}

.amplify-expander__content:has(.expand-to-outer) {
  position: relative;
  padding: 0;
}

.amplify-expander__content:has(.expand-to-outer) .expand-to-outer {
  padding-inline-start: var(--amplify-components-expander-content-padding-inline-start);
  padding-inline-end: var(--amplify-components-expander-content-padding-inline-end);
}

.expand-to-outer {
  padding: 0.4em;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.style-editor::before {
  content: "Block style: ";
  font-weight: bold;
}

.html-editor-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-bottom: 0.5rem;
}

figure img {
  max-width: 100%;
}

.amplify-loader {
  animation: spin 1s ease-in-out infinite;
}

.codex-editor .replacement {
  padding: 0 0.4em;
  background-color: lightgrey;
  border-radius: 0.5em;
}

.codex-editor .cdx-input-placeholder:empty::before {
  content: attr(data-label) attr(data-placeholder);
}

.codex-editor .cdx-input-placeholder::before {
  content: attr(data-label);
  color: var(--amplify-colors-neutral-60);
  position: initial !important;
  display: initial;
}

.codex-editor .block-qanda .question-input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -1px;
}

.codex-editor .block-qanda .answer-input {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.codex-editor .block-textbox .placeholder-input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -1px;
}

.codex-editor .block-textbox .default-input {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
